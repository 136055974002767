import React, { useState, useEffect, useRef } from 'react';
import $ from 'dom7';

import {
  App,
  Views,
  View,
  Toolbar,
  Link,
} from 'framework7-react';

// import PWA from '../js/pwa';
import routes from '../js/routes';

const AppComponent = () => {
  const [activeTab, setActiveTab] = useState('today');
  const previousTab = useRef(null);

  useEffect(() => {
    previousTab.current = activeTab;
  }, [activeTab]);

  // Framework7 Parameters
  const f7params = {
    name: 'RISKADER',
    theme: 'ios',
    routes,
    autoDarkTheme: false,
  };
  if (process.env.NODE_ENV === 'production') {
    // Register service worker in production mode only
    // PWA.init();
  }

  function onTabLinkClick(tab) {
    if (previousTab.current !== activeTab) return;
    if (activeTab === tab) {
      $(`#view-${tab}`)[0].f7View.router.back();
    }
  }
  return (
    <App params={f7params}>

      <Views tabs className="safe-areas">
        <Toolbar tabbar labels bottom>
          <Link
            onClick={() => onTabLinkClick('dzikir')}
            tabLink="#view-dzikir"
            tabLinkActive
            iconF7="book"
            text="Dzikir"
          />
          {/* <Link */}
          {/*  onClick={() => onTabLinkClick('today')} */}
          {/*  tabLink="#view-today" */}
          {/*  iconF7="today" */}
          {/*  text="Today" */}
          {/* /> */}
          {/* <Link */}
          {/*  onClick={() => onTabLinkClick('games')} */}
          {/*  tabLink="#view-games" */}
          {/*  iconF7="rocket_fill" */}
          {/*  text="Games" */}
          {/* /> */}
          {/* <Link */}
          {/*  onClick={() => onTabLinkClick('apps')} */}
          {/*  tabLink="#view-apps" */}
          {/*  iconF7="layers_alt_fill" */}
          {/*  text="Apps" */}
          {/* /> */}
          {/* <Link */}
          {/*  onClick={() => onTabLinkClick('arcade')} */}
          {/*  tabLink="#view-arcade" */}
          {/*  iconF7="gamecontroller_alt_fill" */}
          {/*  text="Arcade" */}
          {/* /> */}
        </Toolbar>

        {/* <View id="view-today" onTabShow={() => setActiveTab('today')} tab url="/today/" /> */}
        <View id="view-dzikir" onTabShow={() => setActiveTab('dzikir')} main tab tabActive url="/dzikir/" />
        {/* <View id="view-games" onTabShow={() => setActiveTab('games')} tab url="/games/" /> */}
        {/* <View id="view-apps" onTabShow={() => setActiveTab('apps')} tab url="/apps/" /> */}
        {/* <View id="view-arcade" onTabShow={() => setActiveTab('arcade')} tab url="/arcade/" /> */}

      </Views>
    </App>
  );
};

export default AppComponent;
