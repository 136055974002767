import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  Block, BlockTitle, Swiper, Col, Row, SwiperSlide, f7ready, Button,
} from 'framework7-react';
import AppstorePage from '../components/AppstorePage';
import ImageSlide from '../components/ImageSlide';

const Dzikir = () => {
  const [title, setTitle] = useState();
  const ref = useRef(null);

  const swal = withReactContent(Swal);

  const pages = [
    { title: '', src: 'static/al-matsurat/page_img_cover.jpg' },
    { title: "Ta'awudz", src: 'static/al-matsurat/page_img_taawudz.jpg' },
    { title: 'Al-Fatihah', src: 'static/al-matsurat/page_img_alfatihah.jpg' },
    { title: 'Al-Baqarah: 1-5', src: 'static/al-matsurat/page_img_albaqarah_1_5.jpg' },
    { title: 'Al-Baqarah: 255 (Ayat Kursi)', src: 'static/al-matsurat/page_img_albaqarah_255.jpg' },
    { title: 'Al-Baqarah: 256-257', src: 'static/al-matsurat/page_img_albaqarah_256_257.jpg' },
    { title: 'Al-Baqarah: 284-286', src: 'static/al-matsurat/page_img_albaqarah_284_286_ar.jpg' },
    { title: 'Al-Baqarah: 284-286', src: 'static/al-matsurat/page_img_albaqarah_284_286_id.jpg' },
    { title: 'Al-Ikhlas', src: 'static/al-matsurat/page_img_alikhlas.jpg' },
    { title: 'Al-Falaq', src: 'static/al-matsurat/page_img_alfalaq.jpg' },
    { title: 'An-Naas', src: 'static/al-matsurat/page_img_annaas.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_1.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_2.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_3.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_4.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_5.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_6.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_7.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_8.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_9.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_10.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_11.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_12.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_13.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_14.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_15.jpg' },
    { title: "Do'a al-matsurat", src: 'static/al-matsurat/page_img_doa_16.jpg' },
    { title: "Do'a Rabithoh", src: 'static/al-matsurat/page_img_rabithoh_1.jpg' },
    { title: "Do'a Rabithoh", src: 'static/al-matsurat/page_img_rabithoh_2.jpg' },
    { title: "Do'a Rabithoh", src: 'static/al-matsurat/page_img_rabithoh_3.jpg' },
  ];

  function onSlideChange() {
    const isLast = ref.current.swiper.activeIndex === pages.length;
    const heading = isLast ? '' : pages[ref.current.swiper.activeIndex].title;
    let pageTitle = `Halaman ${ref.current.swiper.activeIndex}`;
    if (isLast) {
      swal.fire('Selesai 🎉', <p>Alhamdulillah kita udah baca al-matsurat hari ini. Semoga Allah senantiasa selalu melindungi kita semua ya. Aamiin!</p>, 'success');
    } else {
      pageTitle = heading === '' ? '' : `${pageTitle} | ${heading}`;
    }
    setTitle(pageTitle);
  }

  function goto(index) {
    ref.current.swiper.slideTo(index);
  }

  const swiperConfig = {
    autoHeight: true,
    on: {
      slideChangeTransitionEnd: () => onSlideChange(),
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 1,
    },
  };

  useEffect(() => {
    onSlideChange();
    f7ready(() => {
      setTimeout(() => {
        // eslint-disable-next-line react/jsx-one-expression-per-line,jsx-a11y/accessible-emoji
        swal.fire("Assalamu'alaikum", <p>Yuk kita baca al-matsurat 😁<br />Swipe layar smartphone kamu ke kiri atau ke kanan untuk ganti halaman ya!</p>, 'info');
        ref.current.swiper.updateAutoHeight();
      }, 500);
    });
  }, []);

  return (
    <AppstorePage navbarHeading={title} title="Dzikir Pagi & Sore" accountLink={false}>
      <Block style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Row>
          <Col width="100" large="50" style={{ margin: 'auto' }}>
            <Swiper ref={ref} style={{ minHeight: '600px' }} params={swiperConfig}>
              {pages.map((page, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ImageSlide key={index} src={page.src} alt={page.title} />
              ))}
              <SwiperSlide>
                <BlockTitle>Sumber</BlockTitle>
                <Block>
                  <p>Al-Ma'tsurat disusun oleh Imam Syahid Hasan Al-Bana.</p>
                  <p>
                    Dipersembahkan oleh <a href="https://www.instagram.com/riskamenteng/" target="_blank" rel="noopener noreferrer">@riskamenteng</a>
                  </p>
                  <p>
                    Text arab beserta artinya diambil dari aplikasi <a href="https://www.instagram.com/almatsurat.apps/" target="_blank" rel="noopener noreferrer">@almatsurat.apps</a>
                  </p>
                </Block>
                <Block className="buttons-list">
                  <Button onClick={() => goto(0)}>Kembali ke halaman awal</Button>
                </Block>
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Block>
    </AppstorePage>
  );
};

export default Dzikir;
