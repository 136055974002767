import React from 'react';
import { SwiperSlide } from 'framework7-react';
import './ImageSlide.less';

const ImageSlide = ({
  src, alt = '',
}) => {
  return (
    <SwiperSlide>
      <img data-src={src} alt={alt} className="swiper-lazy image-slide" />
      <div className="swiper-lazy-preloader" />
    </SwiperSlide>
  );
};

export default ImageSlide;
